import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { FormLayout } from "./FormLayout";
import { Box, Flex, Image, Text } from "../../../globals/UIKit";
import { LoadingSpinner } from "../../shared/StyledLoadingSpinner";
import { FormHeading } from "./FormHeading";
import { FRESHTEAM_API_URL } from "../../../endpoints";

const API_ERROR_MESSAGE = `There was a problem with your application submission. The system is currently unavailable due to maintenance. Please try again after sometime.`;

export const JobApplicationForm = ({ jobId, toggleJobFormModal, isJobFormActive, location }) => {
  const [firstNameInput, updateFirstName] = useState("");

  const [middleNameInput, updateMiddleName] = useState("");

  const [lastNameInput, updateLastName] = useState("");

  const [emailInput, updateEmail] = useState("");

  const [locationInput, updateLocation] = useState(null);

  const [commentInput, updateComment] = useState("");

  const [resumeInput, updateResume] = useState(null);

  const [coverLetterInput, updateCoverLetter] = useState(null);

  const [portfolioInput, updatePortfolio] = useState(null);

  const [isError, updateError] = useState(false);

  const [formErrorMessage, updateFormErrorMessage] = useState("");

  const [sourceId, updateSourceId] = useState("4000098242");

  const [sourceCategoryId, updateCategoryId] = useState("4000193369");

  const [isNetworkLoading, toggleNetworkLoadingStatus] = useState(false);

  const [isApplicationSuccessfulySubmitted, toggleApplicationSubmissionStatus] = useState(false);

  // EEO Tagline Inputs

  const [genderInput, updateGender] = useState("");

  const [eeoVeteranInput, updateEEOVeteranInput] = useState("");

  const [eeoEthnicityInput, updateEthnicityInput] = useState("");

  const [eeoDisabledInput, updateEEODisabledInput] = useState("");

  const searchParams = new URLSearchParams(location?.search);

  useEffect(() => {
    const sourceId = searchParams.get("ft_source");

    const sourceCategoryId = searchParams.get("ft_medium");

    if (sourceId) {
      const sourceIdInt = sourceId.replace(/[^0-9]/g, "");

      updateSourceId(sourceIdInt);
    }

    if (sourceCategoryId) {
      const categoryIdInt = sourceCategoryId.replace(/[^0-9]/g, "");

      updateCategoryId(categoryIdInt);
    }
  }, [searchParams]);

  // ************************* //

  useEffect(() => {
    if (formErrorMessage) {
      return updateError(true);
    }
  }, [formErrorMessage]);

  // ************************* //

  const trimmedFirstName = firstNameInput.trim();

  // ************************* //

  const handleFormSubmit = e => {
    e.preventDefault();

    if (!trimmedFirstName) {
      return updateFormErrorMessage("Enter First Name");
    }

    const trimmedLastName = lastNameInput.trim();

    if (!trimmedLastName) {
      return updateFormErrorMessage("Enter Last Name");
    }

    if (!locationInput?.label) {
      return updateFormErrorMessage("Enter Location");
    }

    const trimmedEmailInput = emailInput.trim();

    if (!trimmedEmailInput) {
      return updateFormErrorMessage("Enter Email address");
    }

    if (trimmedEmailInput) {
      const emailRegEx = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (!emailRegEx.test(trimmedEmailInput)) {
        return updateFormErrorMessage("Please enter a valid email address");
      }
    }

    if (!resumeInput) {
      return updateFormErrorMessage("Select a file for resume");
    }

    if (resumeInput && resumeInput?.size > 2000000) {
      return updateFormErrorMessage("Size of resume attachment should not be more than 2 MB");
    }

    if (coverLetterInput && coverLetterInput?.size > 2000000) {
      return updateFormErrorMessage("Size of cover letter attachment should not be more than 2 MB");
    }

    if (portfolioInput && portfolioInput?.size > 15000000) {
      return updateFormErrorMessage("Size of portfolio attachment should not be more than 15 MB");
    }

    // POST API call to FreshTeam API ********* //

    let formData = new FormData();

    formData.append("candidate[first_name]", firstNameInput.trim());

    formData.append("candidate[last_name]", lastNameInput.trim());

    formData.append("candidate[email]", trimmedEmailInput);

    formData.append("candidate[location][city]", locationInput?.label);

    formData.append("candidate[resumes][]", resumeInput);

    formData.append("candidate[source_id]", sourceId);

    formData.append("candidate[source_category_id]", sourceCategoryId);

    // Optional Fields ******** //

    // const trimmedGenderInput = genderInput.trim();

    const trimmedMiddleNameInput = middleNameInput.trim();

    const trimmedCommentsInput = commentInput.trim();

    if (genderInput) {
      // formData.append("custom_fields[cf_gender_optional]", trimmedGenderInput);
      formData.append("candidate[eeo_gender]", genderInput);
    }

    if (eeoVeteranInput) {
      formData.append("candidate[eeo_veteran]", eeoVeteranInput);
    }

    if (eeoEthnicityInput) {
      formData.append("candidate[eeo_ethnicity]", eeoEthnicityInput);
    }

    if (eeoDisabledInput) {
      formData.append("candidate[eeo_disabled]", eeoDisabledInput);
    }

    if (trimmedMiddleNameInput) {
      formData.append("candidate[middle_name]", trimmedMiddleNameInput);
    }

    if (trimmedCommentsInput) {
      formData.append("custom_fields[cf_comments]", trimmedCommentsInput);
    }

    if (coverLetterInput) {
      formData.append("candidate[cover_letters][]", coverLetterInput);
    }

    if (portfolioInput) {
      formData.append("candidate[portfolios][]", portfolioInput);
    }

    // Clear Error State - before making API Request
    if (isError) {
      updateFormErrorMessage(null);
      updateError(false);
    }

    toggleNetworkLoadingStatus(true);

    fetch(`${FRESHTEAM_API_URL}/freshteam-applicant?jobId=${jobId}`, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${process.env.GATSBY_FRESHTEAM_API_KEY}`,
      },
      body: formData,
    })
      .then(res => res.json())
      .then(
        result => {
          toggleNetworkLoadingStatus(false);

          if (result && result?.candidate) {
            return toggleApplicationSubmissionStatus(true);
          }

          // Handling Error for 400
          if (result && result?.errors) {
            if (
              result.errors &&
              result.errors.constructor.name === "Array" &&
              !!result.errors.length
            ) {
              updateError(true);

              console.error(`API Error: ${result.errors[0]?.message}`);

              return updateFormErrorMessage(API_ERROR_MESSAGE);
            }
          }
        },
        error => {
          toggleNetworkLoadingStatus(false);

          updateError(true);

          console.error(error);

          updateFormErrorMessage(API_ERROR_MESSAGE);
        }
      );
  };

  // ************************* //

  const getFormBody = () => {
    if (isApplicationSuccessfulySubmitted) {
      return (
        <Flex pt="70px" flexDirection="column">
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1679480581/main-web/icons/newsletter-success_w1mvaj.svg"
            alt="success-icon"
            mb="28px"
            width="45px"
            minWidth="45px"
            minHeight="36px"
            height="36px"
          />
          <Text
            textAlign="center"
            fontSize="18px"
            fontWeight="400"
            lineHeight="1.5"
            color="#111b29"
          >
            Thanks for submitting your application {trimmedFirstName}. Our hiring team will review
            it and get back to you soon.
          </Text>
        </Flex>
      );
    }

    const getEEOInputFields = () => {
      return (
        <Box borderTop="1px solid #e6e6e6" pt="16px" pb="24px">
          <Text color="#000" fontSize="16px" lineHeight="1.5" pb="10px" fontWeight="400">
            Equal Employment Opportunity
          </Text>
          <Box
            as="select"
            // required
            // onChange={e => updateMiddleName(e.target.value)}
            width="100%"
            maxWidth="100%"
            height="50px"
            minHeight="50px"
            borderRadius="4px"
            border="1px solid #e6e6e6"
            px="12px"
            fontSize="16px"
            fontWeight={400}
            color="#111b29"
            mb="14px"
            name=""
            onChange={e => updateGender(e.target.value)}
          >
            <option value="">Select EEO Gender (Optional)</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="dont_wish_to_answer">I don't wish to Answer</option>
          </Box>
          <Box
            as="select"
            onChange={e => updateEEOVeteranInput(e.target.value)}
            width="100%"
            maxWidth="100%"
            height="50px"
            minHeight="50px"
            borderRadius="4px"
            border="1px solid #e6e6e6"
            px="12px"
            fontSize="16px"
            fontWeight={400}
            color="#111b29"
            mb="14px"
            name=""
          >
            <option value="">Select EEO Veteran (Optional)</option>
            <option value="protected_veteran">Protected Veteran</option>
            <option value="not_protected_veteran">Non-Protected Veteran</option>
            <option value="dont_wish_to_answer">I don’t wish to answer</option>
          </Box>
          <Box
            as="select"
            onChange={e => updateEthnicityInput(e.target.value)}
            width="100%"
            maxWidth="100%"
            height="50px"
            minHeight="50px"
            borderRadius="4px"
            border="1px solid #e6e6e6"
            px="12px"
            fontSize="16px"
            fontWeight={400}
            color="#111b29"
            mb="14px"
            name=""
          >
            <option value="">Select EEO Ethnicity (Optional)</option>
            <option value="american_indian_or_alaska_native">
              American Indian or Alaska Native (Not Hispanic or Latino)
            </option>
            <option value="asian_or_indian_subcontinent">
              Asian or Indian Subcontinent (Not Hispanic or Latino)
            </option>
            <option value="black_or_african_american">
              Black or African American (Not Hispanic or Latino)
            </option>
            <option value="hispanic_or_latino">Hispanic or Latino</option>
            <option value="native_hawaiian_or_other_pacific_islander">
              Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)
            </option>
            <option value="two_or_more_races">Two or More Races (Not Hispanic or Latino)</option>
            <option value="white">White (Not Hispanic or Latino)</option>
            <option value="dont_wish_to_answer">I don’t wish to answer</option>
          </Box>
          <Box
            as="select"
            onChange={e => updateEEODisabledInput(e.target.value)}
            width="100%"
            maxWidth="100%"
            height="50px"
            minHeight="50px"
            borderRadius="4px"
            border="1px solid #e6e6e6"
            px="12px"
            fontSize="16px"
            fontWeight={400}
            color="#111b29"
            mb="14px"
            name=""
          >
            <option value="">Select EEO Disabled (Optional)</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
            <option value="dont_wish_to_answer">I don’t wish to answer</option>
          </Box>
        </Box>
      );
    };

    return (
      <Box as="form" width="100%" pt="36px" onSubmit={handleFormSubmit}>
        {/* First Name */}
        <Box
          as="input"
          placeholder="First Name*"
          // required
          onChange={e => updateFirstName(e.target.value)}
          width="100%"
          height="48px"
          borderRadius="4px"
          border="1px solid #e6e6e6"
          px="12px"
          fontSize="16px"
          fontWeight={400}
          color="#111b29"
          mb="14px"
        />
        {/* Middle Name */}
        <Box
          as="input"
          placeholder="Middle Name"
          // required
          onChange={e => updateMiddleName(e.target.value)}
          width="100%"
          height="48px"
          borderRadius="4px"
          border="1px solid #e6e6e6"
          px="12px"
          fontSize="16px"
          fontWeight={400}
          color="#111b29"
          mb="14px"
        />
        {/* Last Name */}
        <Box
          as="input"
          // required
          placeholder="Last Name*"
          onChange={e => updateLastName(e.target.value)}
          width="100%"
          height="48px"
          borderRadius="4px"
          border="1px solid #e6e6e6"
          px="12px"
          fontSize="16px"
          fontWeight={400}
          color="#111b29"
          mb="14px"
        />
        {/* Location */}
        <GooglePlacesAutocomplete
          apiKey="AIzaSyDiBm33mMpFenHOEUUcf4lc55MmHK9ujaw"
          selectProps={{
            locationInput,
            onChange: updateLocation,
            placeholder: "Select location (Start typing to search)*",
            isClearable: true,
            styles: {
              control: provided => ({
                ...provided,
                height: "48px",
                fontWeight: 400,
                fontSize: "16px",
                color: "#111b29",
              }),
              input: provided => ({
                ...provided,
                color: "#111b29",
                fontSize: "16px",
                fontWeight: 400,
              }),
              option: provided => ({
                ...provided,
                color: "#111b29",
                fontSize: "16px",
                fontWeight: 400,
              }),
              singleValue: provided => ({
                ...provided,
                color: "#111b29",
                fontSize: "16px",
                fontWeight: 400,
              }),
            },
          }}
        />
        {/* Email */}
        <Box
          mt="14px"
          as="input"
          placeholder="Email Address*"
          onChange={e => updateEmail(e.target.value)}
          type="email"
          width="100%"
          height="48px"
          borderRadius="4px"
          border="1px solid #e6e6e6"
          px="12px"
          fontSize="16px"
          fontWeight={400}
          color="#111b29"
          mb="14px"
        />
        {/* Comments */}
        <Box
          as="textarea"
          // required
          placeholder="Comments"
          onChange={e => updateComment(e.target.value)}
          type="text"
          width="100%"
          height="50px"
          borderRadius="4px"
          border="1px solid #e6e6e6"
          pt="10px"
          px="12px"
          fontSize="16px"
          fontWeight={400}
          color="#111b29"
          mb="14px"
        />
        {/* Resume */}
        <Text color="#000" fontSize="16px" lineHeight="1.5" pb="10px" fontWeight="400">
          Resume*
        </Text>
        <Flex width="100%" mb="8px">
          <Box
            as="input"
            type="file"
            accept="application/pdf,application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            width="100%"
            fontSize="16px"
            fontWeight={400}
            color="#111b29"
            id="resume-input"
            onChange={e => updateResume(e.target.files[0])}
          />
          <Flex
            as="label"
            for="resume-input"
            bg="#fff"
            height="50px"
            borderRadius="4px"
            border="1px solid #e6e6e6"
            px="12px"
            width="100%"
            justifyContent="center"
          >
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1679391458/main-web/icons/Icon_1_qno3nt.svg"
              alt="attach-icon"
              minWidth="11px"
              width="11px"
              mr="8px"
            />
            <Text color="#111b29" fontSize="16px" lineHeight="1.5" fontWeight="400">
              {resumeInput ? resumeInput?.name : `Choose a file...`}
            </Text>
          </Flex>
        </Flex>
        {/* Cover Letter */}
        <Text color="#000" fontSize="16px" lineHeight="1.5" pb="10px" fontWeight="400">
          Cover Letter
        </Text>
        <Flex width="100%" mb="8px">
          <Box
            as="input"
            type="file"
            accept="application/pdf,application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            width="100%"
            fontSize="16px"
            fontWeight={400}
            color="#111b29"
            id="cover-input"
            onChange={e => updateCoverLetter(e.target.files[0])}
          />
          <Flex
            as="label"
            for="cover-input"
            bg="#fff"
            height="50px"
            borderRadius="4px"
            border="1px solid #e6e6e6"
            px="12px"
            width="100%"
            justifyContent="center"
          >
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1679391458/main-web/icons/Icon_1_qno3nt.svg"
              alt="attach-icon"
              minWidth="11px"
              width="11px"
              mr="8px"
            />
            <Text color="#111b29" fontSize="16px" lineHeight="1.5" fontWeight="400">
              {coverLetterInput ? coverLetterInput?.name : `Choose a file...`}
            </Text>
          </Flex>
        </Flex>
        {/* Portfolio */}
        <Text color="#000" fontSize="16px" lineHeight="1.5" pb="10px" fontWeight="400">
          Portfolio
        </Text>
        <Flex width="100%" mb="16px">
          <Box
            as="input"
            type="file"
            accept="application/pdf,application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            width="100%"
            fontSize="16px"
            fontWeight={400}
            color="#111b29"
            id="portfolio-input"
            onChange={e => updatePortfolio(e.target.files[0])}
          />
          <Flex
            as="label"
            for="portfolio-input"
            bg="#fff"
            height="50px"
            borderRadius="4px"
            border="1px solid #e6e6e6"
            px="12px"
            width="100%"
            justifyContent="center"
          >
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1679391458/main-web/icons/Icon_1_qno3nt.svg"
              alt="attach-icon"
              minWidth="11px"
              width="11px"
              mr="8px"
            />
            <Text color="#111b29" fontSize="16px" lineHeight="1.5" fontWeight="400">
              {portfolioInput ? portfolioInput?.name : `Choose a file...`}
            </Text>
          </Flex>
        </Flex>
        {/* EEO Inputs */}
        {getEEOInputFields()}
        {/* Button */}
        <Flex
          as="button"
          border="none"
          role="button"
          width="100%"
          bg="#1699e2;"
          height="48px"
          justifyContent="center"
          borderRadius="4px"
          id="submit-btn"
          mb="10px"
          disabled={isNetworkLoading}
          opacity={isNetworkLoading ? "0.7" : ""}
        >
          <Text color="#f4fbff" fontSize="16px" fontWeight="600">
            {isNetworkLoading ? `Submitting` : `Submit Application`}
          </Text>
          {isNetworkLoading && <LoadingSpinner />}
        </Flex>
        {isError && (
          <Text fontSize="14px" color="#e65678" fontWeight={400}>
            {`* ${formErrorMessage}`}
          </Text>
        )}
      </Box>
    );
  };

  return (
    <FormLayout toggleJobFormModal={toggleJobFormModal} isJobFormActive={isJobFormActive}>
      <FormHeading
        toggleJobFormModal={toggleJobFormModal}
        isApplicationSuccessfulySubmitted={isApplicationSuccessfulySubmitted}
        toggleApplicationSubmissionStatus={toggleApplicationSubmissionStatus}
      />
      {getFormBody()}
    </FormLayout>
  );
};
