import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { COLORS } from "../../globals/designSystem";

import careersIllusImg from "./images/careers_background.jpg";
import careersMobileIllusImg from "./images/careers_mobile.jpg";
import leftArrowImg from "./images/left_arrow.svg";
import rightArrowImg from "./images/right_arrow.svg";

export const StyledCareersPage = styled.div`
  font-family: "IBM Plex Sans";

  select {
    height: 40px;
    padding: 4px 12px;
    margin-right: 10px;
    background: #fff;
    font-size: 14px;
    color: #333;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    font-weight: 500;
    min-width: 180px;
    // max-width: 325px;
    max-width: 100%;
    font-size: 16px;
    font-weight: normal;

    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
      no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 20px !important;

    option {
      background: #fff;
    }
  }

  .jobDetailsWrapperHeader {
    background-color: ${COLORS.blue_10};
    padding: 32px 0;
    position: sticky;
    top: 72px;
    margin-bottom: 60px;
    z-index: 1000;
  }

  p {
    margin-bottom: 0px;
  }
  .ptb24 {
    padding: 24px 0;
  }
  .jobListHeader {
    padding-bottom: 40px;
  }
  .gridJobHeader {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
  }

  @media (min-width: 1100px) {
    .containerWrapper {
      width: 71%;
      max-width: 1008px !important;
    }
  }

  .layout-spacer {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media (max-width: 800px) {
    .layout-spacer {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  @media (max-width: 600px) {
    .gridJobHeader {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const StyledLandingBanner = styled.section`
  width: 100%;
  min-height: calc(100vh - 75px);
  background-color: #e3e3e3;
  background-image: url(${careersIllusImg});
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 6.5%;

  h1,
  p {
    color: white;
  }

  .landing-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 56px;
      font-weight: 600;
      line-height: 70px;
      width: 70%;
    }

    p {
      padding-top: 10px;
      width: 45%;
      font-size: 32px;
      font-weight: 500;
      line-height: 46px;
      margin-bottom: 40px;
    }

    @media (min-width: 1600px) {
      p {
        width: 40%;
      }
    }
  }

  @media (max-width: 800px) {
    background-image: url(${careersMobileIllusImg});
    background-color: #fff;
    min-height: 429px;
    /* min-height: calc(100vh - 160px); */
    margin-top: -10px;
    padding-top: 7%;

    .landing-content-wrapper {
      h1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        width: 90%;
      }

      p {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        width: 60%;
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.42);
      }
    }
  }
`;

export const StyledHasuraValues = styled.div`
  background: linear-gradient(180deg, #e4e4e4 7.16%, rgba(228, 228, 228, 0) 100%);
  width: 100%;

  .heading-div {
    display: flex;
    margin-bottom: 13px;
    justify-content: center;
    text-align: center;
    padding: 0 5%;

    h2 {
      font-size: 56px;
      font-weight: 600;
      line-height: 70px;
      color: #000;
    }

    .slick-slider {
      display: inline-block;
      min-width: 80px !important;
      width: 80px !important;
      position: relative;
      top: 12px;
    }
  }

  .inspire-div {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    margin-bottom: 60px;

    p {
      width: 100%;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      color: #000;
      padding-top: 24px;
      // text-align: center;
    }
  }

  h3 {
    color: #000;
    font-weight: 500;
    font-size: 32px;
    line-height: 46px;
  }

  .values-wrapper-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .value-top-wrapper {
      width: 30%;
      margin-top: 50px;
      display: block;
    }

    .values-img {
      max-width: 70px;
    }
    .values-box {
      padding-top: 15px;
      color: #000;

      .values-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 4px;
      }

      .values-desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  @media (max-width: 800px) {
    background: #fff;
    text-align: left;

    .heading-div {
      text-align: left;
      justify-content: flex-start;
      padding: 0;
      margin-bottom: 0px;

      h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
      }

      .slick-slider {
        top: 8px;
        min-width: 40px !important;
        width: 40px !important;
      }
    }

    .inspire-div {
      p {
        width: 100%;
        padding-top: 35px;
      }
    }

    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
    }

    .values-wrapper-div {
      .value-top-wrapper {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 35px;

        .values-box {
          margin-top: 15px;
        }
      }
    }
  }
`;

export const StyledHasuraPerks = styled.section`
  background: #fff;
  color: #000;

  h3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 46px;
  }

  .perks-wrapper-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .perks-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 42.5%;
    margin-top: 64px;

    .perk-name {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 11px;
    }

    .perk-desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }
  }

  @media (max-width: 800px) {
    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
    }

    .perks-div {
      width: 100%;
      margin-top: 40px;
    }
  }

  @media (max-width: 650px) {
    padding-top: 140px !important;
  }
`;

export const StyledTeamSlider = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: 90px;

  .slick-list {
    height: 348px;
    margin-bottom: 22px;
  }

  .slick-slide {
    width: auto !important;
  }

  .team_img_div {
    overflow: hidden;

    img {
      min-width: 464px;
      height: 348px;

      margin-left: 12px;
      margin-right: 12px;
    }
  }

  @media (min-width: 1650px) {
    .team_img_div {
      margin-top: 8px;
    }

    .slick-list {
      margin-bottom: 12px;
    }
  }

  @media (max-width: 600px) {
    padding-bottom: 60px;

    .slick-list {
      height: 212px;
      /* margin-bottom: 22px; */
    }

    .team_img_div {
      img {
        min-width: 282px;
        width: 282px;
        height: 212px;
      }
    }
  }
`;

export const StyledTeamMemberQuotes = styled.section`
  color: #000;

  .slick-slide {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .quote_div {
    background-color: #fff !important;
    display: flex !important;
    justify-content: space-between;

    .quote-content-div {
      /* padding-top: 40px; */
    }

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      padding-top: 7%;
      padding-bottom: 7%;
      padding-right: 16%;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }

    .quote_dev_img {
      border-radius: 8px;
      min-width: 349px;
      width: 349px;
      /* box-shadow: 0px 14px 60px 0px rgba(0, 0, 0, 0.16); */
    }
  }

  .slick-arrow {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 2px 6px 0px #00000033;
  }

  .slick-prev {
    background: #fff;
    left: 32px;
    top: unset;
    bottom: -25px;
    z-index: 1;
  }

  .slick-next {
    background: #fff;
    left: 112px;
    top: unset;
    bottom: -25px;
  }

  .slick-prev,
  .slick-next {
    width: 60px;
    height: 60px;
  }

  .slick-prev:before {
    content: url(${leftArrowImg});
  }

  .slick-next:before {
    content: url(${rightArrowImg});
  }

  .slick-prev:focus:before,
  .slick-next:focus:before {
    opacity: 0.75;
  }
  .slick-prev:hover:before,
  .slick-next:hover:before {
    opacity: 1;
  }

  @media (min-width: 1008px) {
    .containerWrapper {
      padding: 0 0px !important;
      min-width: 1008px;
    }
  }

  @media (max-width: 950px) {
    .slick-slide {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }

    .slick-prev {
      background: #fff;
      left: 0px;
      top: unset;
      bottom: -25px;
      z-index: 1;
    }

    .slick-next {
      left: 80px;
    }
  }

  @media (min-width: 650px) and (max-width: 860px) {
    .quote_div {
      h2 {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  @media (max-width: 650px) {
    .quote_div {
      flex-direction: column;
      align-items: flex-start;

      h2 {
        padding-bottom: 0px;
        margin-bottom: -15px;
        padding-right: 0px;
      }

      p {
        position: relative;
        left: 100px;
        bottom: -105px;
      }

      .quote_dev_img {
        width: 90px;
        min-width: 90px;
        height: auto;
      }
    }

    .slick-prev,
    .slick-next {
      bottom: -125px;
    }
  }
`;

export const StyledFreshDeskJobs = styled.section`
  width: 100%;
  background-color: #f8f8f8;
`;

export const StyledJobListWrapper = styled(props => <Link {...props} />)`
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 24px;
  min-height: 100px;
  padding: 25px 40px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 20%);
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 32px;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 6px 28px rgb(0 0 0 / 10%);
  }
  .textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .flexRightCenter {
    display: flex;
    justify-content: right;
    align-items: center;
    svg {
      margin-left: 20px;
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const StyledFormWrapper = styled.div``;
