import React, { useState, useEffect } from "react";
import { Markup } from "interweave";
import { Link } from "gatsby";

import { Box } from "../../globals/UIKit";
import { StyledCareersPage } from "./StyledCareersPage";
import { StyledSubTitle2, StyledDesc2 } from "../shared/StyledTypography";
import { JobApplicationForm } from "./JobApplicationForm";
import StyledButton from "../shared/StyledButton";
import { isBrowser } from "../../utils/constants";

export const JobDetailsPage = ({ jobDetailsData, location }) => {
  const [isJobFormActive, toggleJobFormModal] = useState(false);
  // const [isJobFormActive, toggleJobFormModal] = useState(true);

  useEffect(() => {
    if (isBrowser) {
      const htmlElemenet = document.getElementsByTagName("html")[0];

      if (isJobFormActive) {
        if (htmlElemenet) {
          htmlElemenet.style["overflow-y"] = "hidden";

          htmlElemenet.style.paddingRight = "15px";
        }
      } else {
        htmlElemenet.style["overflow-y"] = "auto";

        htmlElemenet.style.paddingRight = "0px";
      }
    }
  }, [isJobFormActive]);

  return (
    <StyledCareersPage>
      <JobApplicationForm
        location={location}
        jobId={jobDetailsData?.id}
        toggleJobFormModal={toggleJobFormModal}
        isJobFormActive={isJobFormActive}
      />
      <Box className="jobDetailsWrapperHeader">
        <div className="containerWrapper">
          <StyledDesc2 linkVariant="blue_600" fontWeight="font_600" paddingBottom="pb24">
            <a href="https://hasura.io/careers/#hasura-open-roles">All Departments</a>&nbsp;&nbsp;
            <Link to={`/careers/?departmentName=${jobDetailsData.department.name}`}>
              {">"}&nbsp;&nbsp;
              {jobDetailsData.department.name}
            </Link>
          </StyledDesc2>
          <div className="gridJobHeader">
            <div>
              <StyledSubTitle2 variant="neutral_900" fontWeight="font_bold" paddingBottom="pb16">
                {jobDetailsData.title}
              </StyledSubTitle2>
              <StyledDesc2 variant="neutral_900" fontWeight="font_600">
                {jobDetailsData.remote
                  ? "Remote"
                  : `${jobDetailsData.branch.city}, ${jobDetailsData.branch.state}`}
                <br />
                {jobDetailsData.type === "full_time" ? "Full Time" : ""}
              </StyledDesc2>
            </div>
            <div className="buttonWrapper">
              <StyledButton variant="blue" size="md" onClick={() => toggleJobFormModal(true)}>
                Apply Now
              </StyledButton>
            </div>
          </div>
        </div>
      </Box>
      <Box className="containerWrapper" pb="100px" id="freshteam-widget">
        <Markup content={jobDetailsData.description} />
      </Box>
    </StyledCareersPage>
  );
};
